export const arbeitgeberanteil_datatables = [
  {
    year: 2025,
    max_KV: 942.64,
    max_PV: 187.43,
    anteil_AG_KV: 471.32,
    anteil_AG_PV: 93.72,
    delta_Vorjahr: 49.55,
  },
  {
    year: 2024,
    max_KV: 843.53,
    max_PV: 175.95,
    anteil_AG_KV: 421.77,
    anteil_AG_PV: 87.98,
    delta_Vorjahr: 17.78,
  },
  {
    year: 2023,
    max_KV: 807.98,
    max_PV: 169.58,
    anteil_AG_KV: 403.99,
    anteil_AG_PV: 84.79,
    delta_Vorjahr: 19.41,
  },
  {
    year: 2022,
    max_KV: 769.16,
    max_PV: 147.54,
    anteil_AG_KV: 384.58,
    anteil_AG_PV: 73.77,
    delta_Vorjahr: 0.0,
  },
  {
    year: 2021,
    max_KV: 769.16,
    max_PV: 147.54,
    anteil_AG_KV: 384.58,
    anteil_AG_PV: 73.77,
    delta_Vorjahr: 16.61,
  },
  {
    year: 2020,
    max_KV: 735.94,
    max_PV: 142.97,
    anteil_AG_KV: 367.97,
    anteil_AG_PV: 71.48,
    delta_Vorjahr: 16.31,
  },
  {
    year: 2019,
    max_KV: 703.31,
    max_PV: 138.39,
    anteil_AG_KV: 351.66,
    anteil_AG_PV: 69.2,
    delta_Vorjahr: 11.32,
  },
]
