export const beitragsentwicklung_mb100 = [
  [2013, 572],
  [2014, 572],
  [2015, 583],
  [2016, 583],
  [2017, 584],
  [2018, 584],
  [2019, 592],
  [2020, 617],
  [2021, 617],
  [2022, 626],
  [2023, 697],
  [2024, 697],
  [2025, 730],
]
