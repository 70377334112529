var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_vm._v(" Zusatzbeitrag (GKV) "),_c('p'),_vm._v(" Der Zusatzbeitrag (ZB, § 242 SGB V 2015) wurde in Deutschland zum 1. Januar 2015 in der gesetzlichen Krankenversicherung eingeführt. Krankenkassen haben damit ein zusätzliches Mittel, finanzielle Engpässe auszugleichen. Zum anderen soll er den Wettbewerb unter den Krankenkassen fördern und bei den Versicherten für Kostenbewusstsein sorgen. "),_c('p'),_vm._v(" Der Zusatzbeitrag wurde bis in das Jahr 2018 allein durch die Krankenkassenmitglieder getragen. Seit dem 1. Januar 2019 wird der Zusatzbeitrag bei Pflichtversicherten wieder paritätisch finanziert – je zur Hälfte von Arbeitgeber/Rentenversicherung und Arbeitnehmer/Rentner. "),_c('p'),_c('highcharts',{attrs:{"options":_vm.chartOptions}}),_c('p',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by":"year","sort-desc":true,"headers":_vm.headers,"items":_vm.zusatzbeitraege,"items-per-page":100},scopedSlots:_vm._u([{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.year))])]}},{key:"item.bbg",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#95CEFF"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.bbg)))])]}},{key:"item.zusatzbeitrag",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"lightgrey"}},[_vm._v(" "+_vm._s(_vm.toPercent(item.zusatzbeitrag, 2)))])]}},{key:"item.zusatzbeitrag_absolute",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.zusatzbeitrag_absolute))+" ")]}},{key:"item.spread_monthly_absolute",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.spread_monthly_absolute))+" ")]}},{key:"item.zusatzbeitrag_procentual_spread",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.zusatzbeitrag_procentual_spread),"dark":""}},[_vm._v(_vm._s(_vm.toPercent(item.zusatzbeitrag_procentual_spread, 2)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }