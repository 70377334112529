<template>
  <v-container>
    <span class="text-h6"> Beitragsbemessungsgrenze</span>

    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { beitragsbemessungsgrenze } from './data/beitragsbemessungsgrenze/beitragsbemessungsgrenze'
import { absoluterAnstiegHoechstsatzGKV } from './data/beitragsbemessungsgrenze/absoluterAnstiegHoechstsatzGKV'
import { hoechstsatzGKV } from './data/beitragsbemessungsgrenze/hoechstsatzGKV'
//import { jaeg_monthly } from "./data/beitragsbemessungsgrenze/jaeg_monthly";
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    let averageRise = 5.91
    return {
      chartOptions: {
        title: {
          text: 'Beitragsbemessung in der GKV von 1970 bis 2025',
        },
        subtitle: {
          text: '',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          shared: true,
        },
        xAxis: {
          crosshair: {
            snap: true,
          },
        },
        yAxis: [
          //LEFT  ARRAY 0
          {
            title: {
              text: 'Beitragsbemessungsgrenze GKV',
            },
          },
          //LEFT 2 ARRAY 1
          {
            title: {
              text: 'Absolute Veränderung Höchssatz GKV p.a.',
            },
          },

          //RIGHT ARRAY 2
          {
            title: {
              text: 'Höchstsatz GKV',
            },
            opposite: true,
            min: 0,
            max: 1100,
          },
          //RIGHT 2 ARRAY 3
          {
            title: {
              text: 'Durschnittlicher prozentualer Anstieg Höchssatz GKV p.a.',
            },
            opposite: true,
            min: 3,
            max: 9,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Beitragsbemessungsgrenze',
            yAxis: 0,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: beitragsbemessungsgrenze,

            // sample data
          },
          {
            type: 'spline',
            name: 'Absoluter Anstieg / Senkung Höchstsatz GKV p.a.',
            yAxis: 1,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: absoluterAnstiegHoechstsatzGKV,
          },
          {
            type: 'spline',
            name: 'Höchstsatz GKV (ab 1995 inkl. PPV)',
            yAxis: 2,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: hoechstsatzGKV,
          },
          {
            type: 'line',
            name: 'Ø prozentualer Anstieg Höchssatz GKV p.a.',
            yAxis: 3,
            marker: {
              enabled: false,
            },
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [1971, averageRise],
              [1972, averageRise],
              [1973, averageRise],
              [1974, averageRise],
              [1975, averageRise],
              [1976, averageRise],
              [1977, averageRise],
              [1978, averageRise],
              [1979, averageRise],
              [1980, averageRise],
              [1981, averageRise],
              [1982, averageRise],
              [1983, averageRise],
              [1984, averageRise],
              [1985, averageRise],
              [1986, averageRise],
              [1987, averageRise],
              [1988, averageRise],
              [1989, averageRise],
              [1990, averageRise],
              [1991, averageRise],
              [1992, averageRise],
              [1993, averageRise],
              [1994, averageRise],
              [1995, averageRise],
              [1996, averageRise],
              [1997, averageRise],
              [1998, averageRise],
              [1999, averageRise],
              [2000, averageRise],
              [2001, averageRise],
              [2002, averageRise],
              [2003, averageRise],
              [2004, averageRise],
              [2005, averageRise],
              [2006, averageRise],
              [2007, averageRise],
              [2008, averageRise],
              [2009, averageRise],
              [2010, averageRise],
              [2011, averageRise],
              [2012, averageRise],
              [2013, averageRise],
              [2014, averageRise],
              [2015, averageRise],
              [2016, averageRise],
              [2017, averageRise],
              [2018, averageRise],
              [2019, averageRise],
              [2020, averageRise],
              [2021, averageRise],
              [2022, averageRise],
              [2023, averageRise],
              [2024, averageRise],
              [2025, averageRise],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
