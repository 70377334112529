var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('span',{staticClass:"text-h6"},[_vm._v("Der Arbeitgeberanteil")]),_c('p'),_vm._v(" Der Arbeitgeber (AG) beteiligt sich bei Angestellten und dessen mitversicherten Familienangehörigen mit 50 % bis zu einem Höchstbetrag an den tatsächlichen Kosten zur Kranken- und Pflegeversicherung. "),_c('p'),_vm._v(" Diese Regelung gilt für privat und gesetzlich Versicherte gleichermaßen. Er wird im Sozialgesetzbuch (SGB V) geregelt. Der Höchstbetrag wird 2025 wie folgt errechnet: BBG (5512,50 EUR) x 14,6 % (KV) = 804,83 EUR + Zusatzbeitrag (2,45%) = 137,81 EUR = 942,64 EUR + 3,4% (PV) = 187,43 EUR = 1130,07 EUR (davon die Hälfte). "),_c('p'),_vm._v(" Seit 2019 wird der Zusatzbeitrag wieder paritätisch finanziert – je zur Hälfte von Arbeitgeber und Arbeitnehmer. "),_c('p'),_c('v-alert',{attrs:{"tonal":"","text":"","type":"success","variant":"outlined"}},[_vm._v("Der Arbeitgeberanteil hat fast immer einen positiven Effekt bei Beitragsanpassungen. Siehe AG Delta Vorjahr")]),_c('highcharts',{attrs:{"options":_vm.chartOptions}}),_c('p',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by":"year","sort-desc":true,"headers":_vm.headers,"items":_vm.arbeitgeberbeitraege,"items-per-page":100},scopedSlots:_vm._u([{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.year))])]}},{key:"item.max_KV",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#95CEFF"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.formatCurrency(item.max_KV)))])])]}},{key:"item.max_PV",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey"}},[_c('span',{staticStyle:{"color":"black"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.formatCurrency(item.max_PV)))])])])]}},{key:"item.anteil_AG_KV",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#90ED7D"}},[_c('span',{staticStyle:{"color":"#424242"}},[_vm._v(_vm._s(_vm.formatCurrency(item.anteil_AG_KV)))])])]}},{key:"item.anteil_AG_PV",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#f7a35c"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.formatCurrency(item.anteil_AG_PV)))])])]}},{key:"item.delta_Vorjahr",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"#8085E9"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.formatCurrency(item.delta_Vorjahr)))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }