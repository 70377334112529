export const absoluterAnstiegHoechstsatzGKV = [
  [1971, 9.43],
  [1972, 7.9],
  [1973, 13.5],
  [1974, 9.93],
  [1975, 21.67],
  [1976, 21.59],
  [1977, 14.3],
  [1978, 13.11],
  [1979, 10.05],
  [1980, 11.81],
  [1981, 15.49],
  [1982, 17.18],
  [1983, 9.97],
  [1984, 1.07],
  [1985, 17.03],
  [1986, 17.64],
  [1987, 13.42],
  [1988, 21.4],
  [1989, 4.95],
  [1990, 0.23],
  [1991, 2.11],
  [1992, 27.07],
  [1993, 38.81],
  [1994, 14.72],
  [1995, 40.03],
  [1996, 41.57],
  [1997, 11.66],
  [1998, 14.88],
  [1999, 5.87],
  [2000, 2.57],
  [2001, 5.83],
  [2002, 5.9],
  [2003, 28.65],
  [2004, 16.35],
  [2005, 6.0],
  [2006, 2.44],
  [2007, 0.0],
  [2008, 31.16],
  [2009, 43.69],
  [2010, -9.41],
  [2011, 15.96],
  [2012, 23.46],
  [2013, 19.74],
  [2014, 31.89],
  [2015, 13.39],
  [2016, 28.56],
  [2017, 29.01],
  [2018, 9.26],
  [2019, 38.57],
  [2020, 37.2],
  [2021, 40.22],
  [2022, 0.0],
  [2023, 43.38],
  [2024, 90.44],
  [2025, 123.63],
]
