export const beitragsentwicklung_mp100 = [
  [2013, 428],
  [2014, 428],
  [2015, 459],
  [2016, 483],
  [2017, 484],
  [2018, 495],
  [2019, 503],
  [2020, 539],
  [2021, 557],
  [2022, 591],
  [2023, 635],
  [2024, 635],
  [2025, 667],
]
