<template>
  <v-container>
    Zusatzbeitrag (GKV)
    <p></p>
    Der Zusatzbeitrag (ZB, § 242 SGB V 2015) wurde in Deutschland zum 1. Januar 2015 in der gesetzlichen Krankenversicherung eingeführt. Krankenkassen haben damit ein zusätzliches Mittel, finanzielle
    Engpässe auszugleichen. Zum anderen soll er den Wettbewerb unter den Krankenkassen fördern und bei den Versicherten für Kostenbewusstsein sorgen.
    <p></p>
    Der Zusatzbeitrag wurde bis in das Jahr 2018 allein durch die Krankenkassenmitglieder getragen. Seit dem 1. Januar 2019 wird der Zusatzbeitrag bei Pflichtversicherten wieder paritätisch finanziert
    – je zur Hälfte von Arbeitgeber/Rentenversicherung und Arbeitnehmer/Rentner.
    <p></p>
    <highcharts :options="chartOptions"></highcharts>
    <p>
      <v-data-table sort-by="year" :sort-desc="true" :headers="headers" :items="zusatzbeitraege" :items-per-page="100" class="elevation-1">
        <template v-slot:[`item.year`]="{ item }">
          <b>{{ item.year }}</b>
        </template>
        <template v-slot:[`item.bbg`]="{ item }">
          <v-chip color="#95CEFF"> {{ formatCurrency(item.bbg) }}</v-chip>
        </template>
        <template v-slot:[`item.zusatzbeitrag`]="{ item }">
          <v-chip color="lightgrey"> {{ toPercent(item.zusatzbeitrag, 2) }}</v-chip>
        </template>
        <template v-slot:[`item.zusatzbeitrag_absolute`]="{ item }">
          {{ formatCurrency(item.zusatzbeitrag_absolute) }}
        </template>
        <template v-slot:[`item.spread_monthly_absolute`]="{ item }">
          {{ formatCurrency(item.spread_monthly_absolute) }}
        </template>
        <template v-slot:[`item.zusatzbeitrag_procentual_spread`]="{ item }">
          <v-chip :color="getColor(item.zusatzbeitrag_procentual_spread)" dark>{{ toPercent(item.zusatzbeitrag_procentual_spread, 2) }}</v-chip>
        </template>
      </v-data-table>
    </p>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { zusatzbeitrag_datatables } from './data/zusatzbeitrag/zusatzbeitrag_datatables'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Zusatzbeitrag (GKV) von 2015 bis 2025',
        },
        subtitle: {
          text: 'Quelle: Wikipedia</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Zusatzbeitrag (GKV)',
            },
          },
        ],

        series: [
          {
            type: 'spline',
            name: 'Zusatzbeitrag (GKV)',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2025, 2.5],
              [2024, 1.7],
              [2023, 1.6],
              [2022, 1.3],
              [2021, 1.3],
              [2020, 1.1],
              [2019, 0.9],
              [2018, 1.0],
              [2017, 1.1],
              [2016, 1.1],
              [2015, 0.9],
            ],
          },
        ],
      },

      headers: [
        {
          text: 'Jahr',
          align: 'start',
          sortable: true,
          value: 'year',
        },
        { text: 'Beitragsbemessungsgrenze', value: 'bbg' },
        { text: 'Zusatzbeitrag', value: 'zusatzbeitrag' },
        {
          text: 'Zusatzbeitrag (absolut)',
          value: 'zusatzbeitrag_absolute',
        },

        { text: 'Diff. zu Vorjahr (prozentual)', value: 'zusatzbeitrag_procentual_spread' },
      ],
      zusatzbeitraege: zusatzbeitrag_datatables,
    }
  },
  created: function() {
    //this.getPercentage()
  },
  methods: {
    getPercentage: function() {
      //console.log(jaeg_datatables)
    },
    getColor(item) {
      if (item > 10) return 'red'
      else if (item > 5) return 'orange'
      else return 'green'
    },
    formatCurrency(item) {
      //console.log(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item))
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(item)
    },
    toPercent(item, float) {
      const percent = parseFloat(item * 1).toFixed(float) + ' %'
      return percent
    },
  },
}
</script>

<style></style>
