export const beitragsentwicklung_gkv = [
  [2013, 701],
  [2014, 721],
  [2015, 747],
  [2016, 775],
  [2017, 805],
  [2018, 814],
  [2019, 853],
  [2020, 891],
  [2021, 929],
  [2022, 934],
  [2023, 978],
  [2024, 1051],
  [2025, 1174],
]
